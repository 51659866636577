<template>
  <div class="contents" v-if="hasAuth == 2">
    <base-text-contents
        :infoTitle="textContents_attr.infoTitle"
        :text1="textContents_attr.text1"
        :text2="textContents_attr.text2">

      <div class="btnwrap" slot="slot_btns">
        <button type="button" @click="openIdPwPopup()" class="btn btn-block btn-primary btn-lg w200">신규 등록</button>
      </div>

    </base-text-contents>
    <div class="w3-container">
      <base-login-id-pwd-popup :visible="visible1"
                               @toParent:visible="val => visible1 = val"
                               @toParent:submit="(type, val1, val2) => submitForm(type, val1, val2)"
                               :propPopupType="parentPopupType"
      />
    </div>
  </div>

  <div class="contents" v-else-if="hasAuth == 1">
    <base-table-contents
        :caption="tableData_attr.caption"
        :heads="tableData_attr.heads"
        :items="tableData_attr.items"
        :status_fail_msg="tableData_attr.status_fail_msg"
        @toParent:visible1="val => visible1 = val"
        @toParent:deleteCurrRow="val => deleteRow(val)"
        @toParent:updateCreditAuth="openIdPwPopup()">

      <base-card-header slot="slot_card_header"
                        :headerTitle="headerTitle"/>
    </base-table-contents>
    <div class="w3-container">
      <base-login-id-pwd-popup :visible="visible1"
                               @toParent:visible="val => visible1 = val"
                               @toParent:submit="(type, val1, val2) => submitForm(type, val1, val2)"
                               :propPopupType="parentPopupType"
      />
    </div>
  </div>
  <div class="contents" v-else>

  </div>
</template>
<script>
export default {
	name: 'PageAuthCredit',
	data: () => ({
		prefix:"api/auth/credit",
		// Variable
		hasAuth: 0,

		// Components attrs
		headerTitle: '인증정보 관리',
		tableData_attr: {
			caption: '인증정보 관리표',
			heads: [
				{col: 'ID', style: ''},
				{col: 'Password', style: 'width:190px'},
				{col: '등록일자', style: 'width:110px'},
				{col: '상태', style: 'width:90px'},
				{col: '관리', style: 'width:220px'},
			],
			items: [],
			status_fail_msg: [],
		},
		textContents_attr: {
			infoTitle: '인증정보가 등록되지 않았습니다.', 
			text1: '신규등록 버튼을 클릭하여 인증정보를 등록 하세요.',
			text2: '여신금융협회 (카드매출)등을 수집하기위한 인증정보를 등록합니다.',
		},
		// Id/Pw Popup attrs
		visible1: false,
		parentPopupType: "",

		deleteRowIdx: "",
	}),
	methods: {
		deleteRow(idx){
			let delYn = confirm("인증정보를 삭제하시겠습니까?");
			if(!delYn) return false;
			var that = this;
			this.$cAxios.post(this.prefix + "/remove", idx)
			.then(()=>{
				alert("삭제되었습니다");
				that.hasAuth = 2;
				
				let verifyResult = [{}];
				that.$set(that.tableData_attr,'status_fail_msg',verifyResult);

				let resData = [{}];

				that.$set(that.tableData_attr,'items', resData);
			})
			.catch((err)=>{
				if(err.response){
					alert(err.response.data.message);
				}
			});
		},
		initAuthInquiry(){
			//화면 접근시 인증서 초기조회
			var that = this;
			this.$cAxios.post(this.prefix + "/inquiry")
			.then((res)=>{
				if(res.data != null){
					that.hasAuth = 1;

					var status = "";
					let verifyResult = [{}];
					if(res.data.status == "VALID"){
						status = "success";
						verifyResult = [];
						that.$set(that.tableData_attr,'status_fail_msg',verifyResult);
					}else{
						status= "failed";
						verifyResult = [{idx: '1', target: res.data.authId, status_desc: res.data.statusDesc}];
						that.$set(that.tableData_attr,'status_fail_msg',verifyResult);
					}

					let resData = [{
						idx: '1', 
						tds: [
							{text: res.data.authId},
							{text: "*******"},
							{text: res.data.regDt.substring(0,10)}
						],
						td_status: status
					}];

					that.$set(that.tableData_attr,'items', resData);
				}
			})
			.catch(()=>{
				that.hasAuth = 2;
				
				let verifyResult = [{}];
				that.$set(that.tableData_attr,'status_fail_msg',verifyResult);

				let resData = [{}]; 
				that.$set(that.tableData_attr,'items', resData);
			});
		
		},
		openIdPwPopup(){
			this.parentPopupType = "credit"
			this.visible1 = true;
		},
		submitForm(type, id, pwd){
			let idpwData = new Object();
			idpwData.authId = id;
			idpwData.password = pwd;

			this.$cAxios.post(this.prefix + "/regist", JSON.stringify(idpwData) ,{
				headers:{
					'Content-Type':'application/json'
				},
			}).then(()=>{
				alert("등록되었습니다.");
				this.visible1 = false;
			}).catch((err)=>{
				if(err.response){
					alert(err.response.data.message);
				}
			}).finally(()=>{
				this.initAuthInquiry();
				// this.visible1 = false;
			});

			
		},
	},
	created() {
		this.initAuthInquiry();
	}
}
</script>